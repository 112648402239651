import 'core-js/es6/object'
import 'core-js/es6/function'
import 'core-js/es6/parse-int'
import 'core-js/es6/parse-float'
import 'core-js/es6/number'
import 'core-js/es6/math'
import 'core-js/es6/string'
import 'core-js/es6/date'
import 'core-js/es6/array'
import 'core-js/es6/regexp'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'core-js/es6/typed'


import 'core-js'
import 'zone.js'

// if (!Element.prototype.matches) {
// 	Element.prototype.matches = Element.prototype.msMatchesSelector
// }

import 'classlist.js'

import 'core-js/client/shim'


import 'core-js/es6/reflect'
import 'core-js/es7/reflect'
import 'core-js/es7/array'

import 'core-js/es7/object'
import 'web-animations-js'  // Run `npm install --save web-animations-js`.


// import 'mdn-polyfills/Object.assign'

import 'zone.js/dist/zone'  // Included with Angular CLI.

import 'intl'  // Run `npm install --save intl`
